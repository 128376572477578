import { template as template_ea50c19bf09b4e6193182bab2ea178af } from "@ember/template-compiler";
const FKControlMenuContainer = template_ea50c19bf09b4e6193182bab2ea178af(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
