import { template as template_a5afe1c365b7426ab482914b39afbe33 } from "@ember/template-compiler";
const FKLabel = template_a5afe1c365b7426ab482914b39afbe33(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
