import { template as template_c57480d9556247d5abea9d3256a65805 } from "@ember/template-compiler";
const FKText = template_c57480d9556247d5abea9d3256a65805(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
